var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: "popup_wrap popup_wrap_pdf " + _vm.layerStyle },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _vm.type === "A"
        ? [
            _c("div", { staticClass: "popup_cont" }, [
              _c("h1", { staticClass: "page_title" }, [_vm._v("B/L Copy")]),
              _c("div", { staticClass: "content_box" }, [
                _c("p", { staticClass: "text_center mt10 mb10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.oblYn === "Y" &&
                          _vm.memberDetail.userCtrCd === "KR",
                        expression:
                          "oblYn === 'Y' && memberDetail.userCtrCd === 'KR'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.oblYn,
                        expression: "params.oblYn",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "bl_copy_pop_chk0",
                      "true-value": "Y",
                      "false-value": "N",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.params.oblYn)
                        ? _vm._i(_vm.params.oblYn, null) > -1
                        : _vm._q(_vm.params.oblYn, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.params.oblYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.params, "oblYn", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.params,
                                "oblYn",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.params, "oblYn", $$c)
                        }
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.oblYn === "Y" &&
                            _vm.memberDetail.userCtrCd === "KR",
                          expression:
                            "oblYn === 'Y' && memberDetail.userCtrCd === 'KR'",
                        },
                      ],
                      staticClass: "mr20",
                      attrs: { for: "bl_copy_pop_chk0" },
                    },
                    [_c("span"), _vm._v("Original Copy")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.chkEsign === "Y",
                        expression: "chkEsign === 'Y'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.eSignYn,
                        expression: "params.eSignYn",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "bl_copy_pop_chk1",
                      "true-value": "Y",
                      "false-value": "N",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.params.eSignYn)
                        ? _vm._i(_vm.params.eSignYn, null) > -1
                        : _vm._q(_vm.params.eSignYn, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.params.eSignYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.params, "eSignYn", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.params,
                                "eSignYn",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.params, "eSignYn", $$c)
                        }
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.chkEsign === "Y",
                          expression: "chkEsign === 'Y'",
                        },
                      ],
                      staticClass: "mr20",
                      attrs: { for: "bl_copy_pop_chk1" },
                    },
                    [_c("span"), _vm._v("E-Sign")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.porDlyYn,
                        expression: "params.porDlyYn",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "bl_copy_pop_chk2",
                      "true-value": "Y",
                      "false-value": "N",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.params.porDlyYn)
                        ? _vm._i(_vm.params.porDlyYn, null) > -1
                        : _vm._q(_vm.params.porDlyYn, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.params.porDlyYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.params,
                                "porDlyYn",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.params,
                                "porDlyYn",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.params, "porDlyYn", $$c)
                        }
                      },
                    },
                  }),
                  _vm._m(0),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.noCnee,
                        expression: "params.noCnee",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "bl_copy_pop_chk3",
                      "true-value": "N",
                      "false-value": "Y",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.params.noCnee)
                        ? _vm._i(_vm.params.noCnee, null) > -1
                        : _vm._q(_vm.params.noCnee, "N"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.params.noCnee,
                          $$el = $event.target,
                          $$c = $$el.checked ? "N" : "Y"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.params, "noCnee", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.params,
                                "noCnee",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.params, "noCnee", $$c)
                        }
                      },
                    },
                  }),
                  _vm._m(1),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.noNotify,
                        expression: "params.noNotify",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "bl_copy_pop_chk4",
                      "true-value": "N",
                      "false-value": "Y",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.params.noNotify)
                        ? _vm._i(_vm.params.noNotify, null) > -1
                        : _vm._q(_vm.params.noNotify, "N"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.params.noNotify,
                          $$el = $event.target,
                          $$c = $$el.checked ? "N" : "Y"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.params,
                                "noNotify",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.params,
                                "noNotify",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.params, "noNotify", $$c)
                        }
                      },
                    },
                  }),
                  _vm._m(2),
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.chkRider === "Y",
                        expression: "chkRider === 'Y'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.rider,
                        expression: "params.rider",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "bl_copy_pop_chk5",
                      "true-value": "Y",
                      "false-value": "N",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.params.rider)
                        ? _vm._i(_vm.params.rider, null) > -1
                        : _vm._q(_vm.params.rider, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.params.rider,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.params, "rider", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.params,
                                "rider",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.params, "rider", $$c)
                        }
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.chkRider === "Y",
                          expression: "chkRider === 'Y'",
                        },
                      ],
                      attrs: { for: "bl_copy_pop_chk5" },
                    },
                    [_c("span"), _vm._v("Rider")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "mt10 text_center" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnChangePrint()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ARAI200.007")))]
                ),
              ]),
            ]),
          ]
        : [
            _c("div", { staticClass: "popup_cont" }, [
              _c("h1", { staticClass: "page_title" }, [_vm._v("B/L Copy")]),
              _c("div", { staticClass: "content_box" }, [
                _c("div", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "float_left col_3" }, [
                    _c("table", { staticClass: "tbl_list font_sm gridtype" }, [
                      _vm._m(3),
                      _c("thead", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.002"))),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.items, function (vo, idx) {
                          return _c("tr", { key: "tbl_" + idx }, [
                            _c("td", [_vm._v(_vm._s(vo.blNo))]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "float_left text_center col_7 pl20",
                      staticStyle: {
                        "max-height": "500px",
                        "overflow-y": "auto",
                      },
                    },
                    [
                      _c("vue-pdf-app", {
                        staticStyle: { height: "300px" },
                        attrs: {
                          pdf: _vm.pdf.pdfUrl,
                          config: _vm.pdfConfig.config,
                          "id-config": _vm.pdfConfig.idConfig,
                          theme: "light",
                        },
                      }),
                      _c("div", { staticClass: "paging print" }, [
                        _c("span", { staticClass: "num" }, [
                          _c("input", {
                            staticClass: "pageNumber",
                            attrs: {
                              type: "number",
                              id: _vm.pdfConfig.idConfig.pageNumber,
                              maxlength: "2",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "pageNumber",
                              attrs: { id: _vm.pdfConfig.idConfig.numPages },
                            },
                            [_vm._v("-")]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "mt10 text_center" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnDownlaod()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.101")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { id: _vm.pdfConfig.idConfig.print },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.102")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openSendEmailPopup()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.103")))]
                ),
              ]),
            ]),
            _c(
              "win-layer-pop",
              { staticClass: "pdf_send_email" },
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.sendEmailParam },
                      on: { close: _vm.closeSendEmailPopup },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "mr20", attrs: { for: "bl_copy_pop_chk2" } },
      [_c("span"), _vm._v("POR & DLY")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "mr20", attrs: { for: "bl_copy_pop_chk3" } },
      [_c("span"), _vm._v("CNEE address")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "mr20", attrs: { for: "bl_copy_pop_chk4" } },
      [_c("span"), _vm._v("Notify address")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "100%" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }